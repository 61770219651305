import { TbMapPinX } from "solid-icons/tb";
import { Show } from "solid-js";
import { Anchor } from "~/components/CTA/Anchor";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./NotFoundScreen.module.css";

interface Props {
  modifier?: "full-screen";
}

export const NotFoundScreen = (props: Props) => {
  return (
    <div
      class={classNames(
        styles["not-found-screen"],
        props.modifier === "full-screen" ? styles["not-found-screen--full-screen"] : "",
      )}
    >
      <div class={styles["not-found-screen__container"]}>
        <div class={styles["not-found-screen__header"]}>
          <StIcon icon={TbMapPinX} size="5rem" class={styles["not-found-screen__icon"]} />
        </div>
        <div class={styles["not-found-screen__content"]}>
          <p class={styles["not-found-screen__title"]}>Not found.</p>
          <p class={styles["not-found-screen__subtitle"]}>Access denied or page missing.</p>
        </div>

        <Show when={props.modifier === "full-screen"}>
          <div class={styles["not-found-screen__actions"]}>
            <Anchor
              data-test-id="404-return-to-homepage"
              target="_self"
              href="/"
              accessibleSuffix="As this page is missing"
              label="Return to homepage"
            />
          </div>
        </Show>
      </div>
    </div>
  );
};
